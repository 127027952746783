@use "arts/assets/variables" as *;

$footer-padding: 60px;
$footer-padding-mobile: 30px;

.border {
  margin-top: $footer-padding;
  border-top: 1px solid $checkout-line;
  padding-top: $footer-padding;
  @media (max-width: $w-1-mobile) {
    margin-top: $footer-padding-mobile;
    padding-top: $footer-padding-mobile;
  }
}

.footer, .copy {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-family: $proxima-font-family;
  padding: 0;

  @media (min-width: $m-w-2) {
    font-size: 14px;
  }

  @media (min-width: $m-w-4) {
    font-size: 16px;
  }

  @media (max-width: $w-1-mobile) {
    font-size: 14px;
    flex-direction: column-reverse;
  }
}

.copy {
  flex-direction: row-reverse;

  @media (max-width: $w-1-mobile) {
    flex-direction: row;
  }

  @media (min-width: $m-w-4) {
    margin: 0 0 120px 0;
  }

  @media (max-width: $w-1-mobile) {
    margin: 12px 0 36px;
  }

  span {
    display: block;

    @media (min-width: $m-w-3) {
      display: inline;
    }

    @media (min-width: $m-w-4) {
      display: inline;
    }

    @media (max-width: $w-1-mobile) {
      display: inline;
    }
  }
}


.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex: 1;
  display: grid;
  grid-template-columns: $grid-f1 $grid-f1 $grid-f1 $grid-f1 $grid-f1w;
  grid-column-gap: $grid-fg-1;

  @media (max-width: $w-1-mobile) {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
  }

  @media (min-width: $m-w-2) {
    grid-template-columns: $grid-f2 $grid-f2 $grid-f2 $grid-f2 $grid-f2w;
    grid-column-gap: $grid-fg-2;
  }

  @media (min-width: $m-w-3) {
    grid-template-columns: $grid-f3 $grid-f3 $grid-f3 $grid-f3 $grid-f3w;
    grid-column-gap: $grid-fg-3;
  }

  @media (min-width: $m-w-4) {
    grid-template-columns: $grid-f4 $grid-f4 $grid-f4 $grid-f4 $grid-f4w;
    grid-column-gap: $grid-fg-4;
  }

  li {
    display: flex;
    flex-direction: column;

    @include navigational-links;

    @media (max-width: $w-1-mobile) {
      padding: 12px 0 0 0;

      h5 {
        display: flex;
        justify-content: space-between;
      }
    }

    &.share {
      a {
        display: inline-flex;
        padding-left: 6px;
      }

      @media (max-width: $w-1-mobile) {
        flex-direction: row;
        justify-content: space-around;

        h5, span {
          display: none;
        }

        border-top: 1px solid #ccc;
        margin-top: 12px;
        padding: 24px 0;
      }

    }
  }
}

.subscribe {
  margin-bottom: 36px;
}

.subscribe, .copy > div {
  width: $grid-fs-1;

  @media (min-width: $m-w-2) {
    width: $grid-fs-2;
  }

  @media (min-width: $m-w-3) {
    width: $grid-fs-3;
  }

  @media (min-width: $m-w-4) {
    width: $grid-fs-4;
  }

  @media (max-width: $w-1-mobile) {
    width: 100%;
  }
}

.copy > div {
  @media (max-width: $w-1-mobile) {
    text-align: center;
  }
}
