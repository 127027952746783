@use "arts/assets/variables" as *;

.root {
  @include page-title;
}

.section {
  margin: (-1px) 0 0 0;
  padding: 0;
  font-family: $proxima-font-family;
  font-size: 14px;

  @media (min-width: $m-w-4) {
    font-size: 16px;
  }

  h2 {
    font-family: $proxima-font-family;
    font-size: 12px;
    font-weight: normal;
    height: 25px;
    display: flex;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    align-items: center;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.5px;

    @media (min-width: $m-w-4) {
      height: 35px;
      font-size: 14px;
    }

    a {
      font-size: 13px;
      color: #000;
      text-decoration: underline;
      font-weight: normal;
      margin: 0 6px 0 0;

      &:hover, &:active {
        color: #000;
      }

      @media (min-width: $m-w-4) {
        font-size: 15px;
        margin: 0 9px;
      }
    }
  }

  .content {
    margin: 18px 0;
    margin-top: 18px !important;

    @media (min-width: $m-w-4) {
      margin: 24px 0;
      margin-top: 24px !important;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &.have, &.nothave {
      padding: 25px 0;
      margin: 0 !important;
    }
    &.have {
      border-bottom: 8px solid #ccc;
    }
  }
}

.button {
  background: #000;
  color: #fff;
  padding: 10px 12px !important;
  @include sparsed-button;
}

.button, .applyButton {
  min-width: 210px;
  height: 40px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px !important;
}

.applyButton {
  color: #000 !important;
  background: #fff !important;
  border: 1px solid #000 !important;
}

.t2 {
  margin-bottom: 18px !important;
}

.t1, .t2 {
  font-weight: bold;
  font-size: 16px;

  @media (min-width: $m-w-4) {
    margin-bottom: 18px !important;
  }
}

.genericForm {
  @include generic-form;
}

.giftMessage {
  textarea {
    height: 80px;
  }
}

.actionLink {
  font-family: $proxima-font-family;
  outline: none;
  border: none;
  background: #fff;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 13px;

  @media (min-width: $m-w-4) {
    font-size: 15px;
  }
}

.thankyou {
  flex-direction: column;

  .more {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 45px;
    img {
      max-width: 100%;
      border: 1px solid #000;
      @media (min-width: 940px) {
        max-width: 70%;
      }
      @media (min-width: 2560px) {
        width: 50%;
      }
    }
  }

  h1 {
    font-family: $web-pro-font-family;
    font-size: 24px;
    letter-spacing: 3.3px;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: normal;
    padding: 27px 0 0;

    @media (min-width: $m-w-2) {
      font-size: 28px;
      padding: 45px 0 0;

    }
    @media (min-width: $m-w-3) {
      font-size: 38px;
      padding: 60px 0 0;
    }
    @media (min-width: $m-w-4) {
      font-size: 52px;
      padding: 69px 0 0;
    }

    em {
      display: block;
      width: 80px;
      border-bottom: 1px solid #000;
      margin: 19px auto;

      @media (min-width: $m-w-2) {
        margin: 24px auto;
      }
      @media (min-width: $m-w-4) {
        margin: 36px auto;
      }
    }
  }

  h3 {
    font-family: $web-pro-font-family;
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 3.3px;
    text-align: center;
    line-height: 180%;

    span {
      font-style: italic;
      display: block;
    }

    div {
      margin-top: 12px;
      text-transform: uppercase;
      font-family: $ff-crimson-font-family;
      font-size: 27px;
      letter-spacing: 5px;
    }

    @media (max-width: $w-1-mobile) {
      div {
        font-size: 21px;
      }
    }

    @media (min-width: $m-w-2) {
      font-size: 28px;
      div {
        font-size: 30px;
      }
    }

    @media (min-width: $m-w-3) {
      font-size: 31px;
      div {
        font-size: 33px;
      }
    }

    @media (min-width: $m-w-4) {
      font-size: 42px;
      div {
        font-size: 41px;
      }
    }

  }

  .print {
    line-height: 150%;
    text-align: center;
    font-family: $proxima-font-family;
    font-size: 14px;
    margin: 19px 0;

    @media (min-width: $m-w-2) {
      font-size: 14px;
      margin: 24px 0;
    }
    @media (min-width: $m-w-4) {
      font-size: 18px;
      margin: 36px 0;
    }

    button {
      margin: 0 auto;
    }
  }

  .signUp {
    div:first-child {
      font-size: 16px;
    }
  }

  .info {
    display: flex;
    justify-content: center;
    text-align: left;

    div {
      line-height: 150%;
    }

    div:first-child {
      font-weight: bold;
      margin-right: 18px;
    }
  }

  .signUp {
    margin: 0;
    flex-direction: column;
    align-items: center;

    div {
      margin-bottom: 12px;
    }
  }
}

.signUpDelimiter {
  padding-bottom: 45px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 18px;
}

.unsubscribe {
  margin: 17px 0 !important;
  @include custom-chekbox;
}

.placeOrderH {
  margin: 6px 0 15px;
  flex-direction: column;
  align-items: flex-start !important;
}

.newCustomer {
  display: flex;
}

.guest {
  width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
