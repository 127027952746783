@use "arts/assets/variables" as *;

$mobile-button-width: 216px;

.subscribe {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  form {
    padding: 0;
    margin: 0;
  }

  input[type="email"] {
    font-family: $proxima-font-family;
    border: 1px solid $grey-border;
    height: 36px;
    width: 100%;
    margin: 0 0 12px 0;
    border-radius: 0;
    outline: none;

    &:focus {
      box-shadow: none;
    }
  }

  .button {
    font-size: 16px;
    background: #000;
    color: #fff;
    height: 36px;
    padding: 9px 27px;
    border: 1px solid #000;
    font-family: $proxima-font-family;
    letter-spacing: 1px;
    margin: 0;
    width: 100%;
  }

  .error {
    color: red;
    margin-bottom: 6px;
  }
}


.subscribeFooter {
  width: $grid-fs-1;
  @include navigational-links;

  h5 {
    margin: 0;
    line-height: 100%;
    margin-top: 5px !important;
    text-align: center;

    @media (max-width: $w-1-mobile) {
      line-height: 100%;
      text-align: left;
    }

    @media (min-width: $m-w-4) {
      margin-top: 8px !important;
    }
  }

  p {
    font-family: $proxima-font-family;
    font-size: 14px;
    margin: 0;
    padding: 6px 0;
    line-height: 18px;
    text-align: center;

    @media (max-width: $w-1-mobile) {
      line-height: 100%;
      text-align: left;
    }
  }


  @media (min-width: $m-w-2) {
    width: $grid-fs-2;
  }

  @media (min-width: $m-w-3) {
    width: $grid-fs-3;
  }

  @media (min-width: $m-w-4) {
    width: $grid-fs-4;
  }

  @media (max-width: $w-1-mobile) {
    width: 100%;
  }
}

.label {
font-size: 22px;
font-family: $ff-crimson-font-family;
padding-bottom: 26px;

@media (max-width: $w-1-mobile) {
  font-size: 22px;
}

@media (min-width: $m-w-2) {
  font-size: 24px;
}

@media (min-width: $m-w-4) {
  font-size: 27px;
}

@media (min-width: $m-w-4) {
    font-size: 30px;
  }
}

.subscribeForm {
  width: 300px;
  margin: 0 auto;
}
