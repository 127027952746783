@use "arts/assets/variables" as *;

.root {
  @include page-title;

  img.giftsHeader {
    width: 100%;
  }

  p a {
    color: #000;
    text-decoration: underline;
    vertical-align: baseline;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
}

ul.grid {
  @include popularArtGrid;
}
