@use "arts/assets/variables" as *;

.title {
  @include width-keeper;
  @include page-title;
}

.selectGrid {
  display: flex;
  min-height: 21px;
  margin: 9px 0 9px;
  @media (max-width: $w-1-mobile) {
    flex: 1;
    justify-content: space-between;
  }
}

.gridSlection {
  border: none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 0;
  margin: 0 0 0 12px;
  background: #fff;
  color: #000;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  @media (max-width: $w-1-mobile) {
    margin: 0;
  }

  &:hover, &:active, &:focus {
    color: #000;
  }

  img {
    margin-right: 3px;
    width: 12px;
    height: 12px;
  }
}

.gridType {
  border-bottom: 1px solid #000;
}

.bcrumbs {
  @include width-keeper;

  & {
    background: #fff;
    margin: 0 auto;
  }
}

.wallBc {
  @media (max-width: $w-1-mobile) {
    display: none;
  }
}

.grid {
  grid-row-gap: 27px;

  @media (min-width: $m-w-2) {
    grid-row-gap: 33px;
  }

  @media (min-width: $m-w-3) {
    grid-row-gap: 37px;
  }

  @media (min-width: $m-w-4) {
    grid-row-gap: 43px;
  }

  li {
    a.wall {
      color: #000;
      text-decoration: none;
      display: block;
      width: 100%;

      &:hover, &:active, &:focus {
        color: #000;
      }

      img {
        display: flex;
        width: 100%;

        &:before {
          display: block;
          content: "";
          padding-top: 66.66%;
        }
      }
    }

    h3 {
      font-family: $ff-crimson-font-family;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      margin: 0;
      padding: 15px 0;
      text-align: center;
      letter-spacing: 0.4px;

      @media (min-width: $m-w-4) {
        font-size: 28px;
      }
    }

    p {
      font-family: $proxima-font-family;
      font-size: 14px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
      margin-bottom: 21px;
    }

    .shop {
      text-align: center;
    }

    .sharing {
      padding: 9px 0;
      display: flex;
      flex-direction: row-reverse;
      position: relative;
    }

    .favorite {
      margin-right: 6px;
    }

    button {
      outline: none;
      background: #fff;
      color: #000;
      margin: 0 6px 0 0;
      padding: 0;
      border: none;
    }
  }
}

.carousel {
  position: relative;
  @include width-keeper;

  button.prev, button.next {
    background: #fff;
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
    position: absolute;
    top: 280px;

    @media (min-width: $m-w-2) {
      top: 320px;
    }

    @media (min-width: $m-w-3) {
      top: 360px;
    }

    @media (min-width: $m-w-4) {
      top: 460px;
    }
  }

  button.prev {
    left: -37px;

    @media (max-width: $w-1-mobile) {
      left: 1px;
      top: $mobile-arrow-top;
    }

    @media (min-width: $m-w-2) {
      left: -48px;
    }

    @media (min-width: $m-w-4) {
      left: -85px;
    }
  }

  button.next {
    left: calc(100% + 15px);

    @media (max-width: $w-1-mobile) {
      left: auto;
      right: 1px;
      top: $mobile-arrow-top;
    }

    @media (min-width: $m-w-2) {
      left: calc(100% + 26px);
    }

    @media (min-width: $m-w-4) {
      left: calc(100% + 47px);
    }
  }

  .keeper {
    overflow: hidden;
  }
}

.carouselInner {
  display: grid;
  grid-template-columns: repeat(3, 100%);
  grid-column-gap: 0;


  &.moving {
    transition: transform 0.5s;
  }

  .left {
    @include width-keeper;
    & {
      background: #fff;
      display: block;
    }
  }
}

.mobileHidden {
  @media (max-width: $w-1-mobile) {
    display: none !important;
  }
}
