@use "arts/assets/variables" as *;

.loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.artists {
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  @include width-keeper;


}

.letter {
  width: 320px;
  padding: 0 35px 15px;
  display: flex;
  flex-direction: column;

  @media (min-width: $m-w-2) {
    width: 370px;
    // padding: 0 35px 15px;
  }

  @media (min-width: $m-w-3) {
    width: 412px;
    padding: 0 45px 25px;
  }

  @media (min-width: $m-w-4) {
    width: 550px;
    padding: 0 75px 25px;
  }
}

.namesKeeper {
  flex: 1;
  height: 500px;
  overflow-y: auto;
}

.names {
  flex: 1;
  display: grid;
  grid-template-columns: 125px 125px 125px 125px;
  grid-column-gap: 20px;
  grid-auto-flow: column;

  @media (min-width: $m-w-2) {
    grid-column-gap: $grid-cg-1;
    grid-template-columns: 152px 152px 152px 152px;
  }

  @media (min-width: $m-w-3) {
    grid-template-columns: 183px 183px 183px 183px;
  }

  @media (min-width: $m-w-4) {
    grid-template-columns: 275px 275px 275px 275px;
  }
}

.names {
  a {
    padding: 5px 0;
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    &:hover, &:active {
      text-decoration: underline;
      color: #000;
    }
    font-size: 14px;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;

    @media (min-width: $m-w-4) {
      font-size: 16px;
    }
  }
}

.letters {
  display: grid;
  grid-template-columns: auto auto auto auto auto;

  button {
    color: #000;
    border: 0;
    background: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    height: 50px;

    &:hover {
      text-decoration: underline;
    }

    @media (min-width: $m-w-2) {
      height: 60px;
    }

    @media (min-width: $m-w-3) {
      height: 65px;
    }

    @media (min-width: $m-w-4) {
      height: 80px;
    }
  }
}

.current {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 35px;

  // @media (min-width: $m-w-2) {
  //   margin-bottom: 45px;
  // }

  // @media (min-width: $m-w-3) {
  //   margin-bottom: 65px;
  // }

  // @media (min-width: $m-w-4) {
  //   margin-bottom: 116px;
  // }
}
