@use "arts/assets/variables" as *;

.navButton {
  position: absolute;
  top: 45%;
  height: 10px;
  width: 10px;
  cursor: pointer;
  transform: rotate(50deg);
}

.prevButton {
  composes: navButton;
  left: 0;
  border-bottom: 3px solid $grey;
  border-left: 3px solid $grey;
  @media (max-width: $art-page-mobile-break) {
    left: 7px;
  }
}

.nextButton {
  composes: navButton;
  right: 0;
  border-top: 3px solid $grey;
  border-right: 3px solid $grey;
  @media (max-width: $art-page-mobile-break) {
    right: 7px;
  }
}
